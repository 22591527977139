import React, { useEffect, useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  malaysianOnly,
  purchasedPhysical,
  eligibleGalaOnly,
  physical,
  galaOnly,
  physicalDay1,
  physicalDay2,
  physicalDay3,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedGalaOnly, setSelectedGalaOnly] = useState(null);
  const [pax, setPax] = useState(0);
  const [wheelChair, setWheelChair] = useState(false);
  const [selectedPhysicalDay1, setSelectedPhysicalDay1] = useState(null);
  const [selectedPhysicalDay2, setSelectedPhysicalDay2] = useState(null);
  const [selectedPhysicalDay3, setSelectedPhysicalDay3] = useState(null);

  const resetGalaOnly = () => {
    setPax(0);
    setWheelChair(false);
  };

  useEffect(() => {
    if (selectedGalaOnly === null) {
      resetGalaOnly();
    }
  }, [selectedGalaOnly]);

  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[
            selected,
            selectedGalaOnly,
            selectedPhysicalDay1,
            selectedPhysicalDay2,
            selectedPhysicalDay3,
          ]}
        />
        <input type="hidden" name="pax_coming_with" value={pax} />
        <input type="hidden" name="wheelchair" value={wheelChair} />

        <ProductCard
          cardTitle={"ICSBH 2024 Conference Ticket"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
            } else {
            }
          }}
          handleRadio={() => {
            setSelectedGalaOnly(null);
            setSelectedPhysicalDay1(null);
            setSelectedPhysicalDay2(null);
            setSelectedPhysicalDay3(null);
          }}
        />

        {eligibleGalaOnly && (
          <ProductCard
            cardTitle={"ICSBH 2024 Gala Dinner Ticket"}
            products={galaOnly}
            selectedState={selectedGalaOnly}
            setSelectedState={setSelectedGalaOnly}
            activated
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedGalaOnly(null);
              }
            }}
            handleRadio={() => {
              setSelected(null);
            }}
          />
        )}

        {selectedGalaOnly && (
          <div className="text-center">
            <h5 className="pt-4">Are you a wheelchair user?</h5>
            <div className="d-flex justify-content-center">
              <select
                className="form-control"
                style={{ maxWidth: "150px" }}
                value={wheelChair}
                onChange={(e) => setWheelChair(e.target.value)}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <h5 className="pt-4">Are you coming with anyone else?</h5>
            <small className="fst-italic">
              (This is for table distribution purposes only. If you would like
              to purchase additional ticket, please proceed to create another
              account.)
            </small>

            <div className="d-flex justify-content-center">
              <select
                className="form-control"
                style={{ maxWidth: "150px" }}
                value={pax}
                onChange={(e) => setPax(e.target.value)}
              >
                <option value="0">No one else</option>
                {Array.from({ length: 10 }).map((_, index) => (
                  <option key={index} value={index}>
                    {selectedGalaOnly && index}
                  </option>
                ))}
              </select>
            </div>

            {/* if selected more than 0, show the field for the pax names */}
            {pax > 0 && (
              <div className="d-flex flex-column align-items-center">
                <h5 className="pt-4">Whom are you coming with?</h5>
                {Array.from({ length: pax }, (_, index) => (
                  <div
                    key={index}
                    className="comingWith"
                    style={{ width: "400px" }}
                  >
                    <h6 className="pt-1">Accompanying Person {index + 1}</h6>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id={`comingWith${index + 1}`}
                        name={`comingWith${index + 1}`}
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {!purchasedPhysical && malaysianOnly && (
          <>
            <h5 className="text-center pt-5">Daily Pass Ticket</h5>
            <ProductCard
              cardTitle={"ICSBH 2024 Conference Day 1 Ticket"}
              products={physicalDay1}
              selectedState={selectedPhysicalDay1}
              setSelectedState={setSelectedPhysicalDay1}
              activated
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedPhysicalDay1(null);
                }
              }}
              handleRadio={() => {
                setSelected(null);
                if (selectedPhysicalDay2 && selectedPhysicalDay3) {
                  setSelectedPhysicalDay2(null);
                  setSelectedPhysicalDay3(null);
                }
              }}
            />
            <ProductCard
              cardTitle={"ICSBH 2024 Conference Day 2 Ticket"}
              products={physicalDay2}
              selectedState={selectedPhysicalDay2}
              setSelectedState={setSelectedPhysicalDay2}
              activated
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedPhysicalDay2(null);
                }
              }}
              handleRadio={() => {
                setSelected(null);
                if (selectedPhysicalDay1 && selectedPhysicalDay3) {
                  setSelectedPhysicalDay1(null);
                  setSelectedPhysicalDay3(null);
                }
              }}
            />
            <ProductCard
              cardTitle={"ICSBH 2024 Conference Day 3 Ticket"}
              products={physicalDay3}
              selectedState={selectedPhysicalDay3}
              setSelectedState={setSelectedPhysicalDay3}
              activated
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedPhysicalDay3(null);
                }
              }}
              handleRadio={() => {
                setSelected(null);
                if (selectedPhysicalDay1 && selectedPhysicalDay2) {
                  setSelectedPhysicalDay1(null);
                  setSelectedPhysicalDay2(null);
                }
              }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <small className="fst-italic">
                  <b>Note:</b> By purchasing daily tickets, you will receive a
                  certificate of attendance. Please note that the Malaysian
                  Medical Association (MMA) does not award CPD points for daily
                  ticket holders.
                </small>
              </div>
            </div>
          </>
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="theme-btn d-block mx-auto mt-4"
          disabled={
            galaOnly
              ? selected === null &&
                selectedGalaOnly === null &&
                selectedPhysicalDay1 === null &&
                selectedPhysicalDay2 === null &&
                selectedPhysicalDay3 === null
              : selected === null
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
