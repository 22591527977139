import React, { useEffect, useState } from "react";

export const CommunityCard = ({
  index,
  owner,
  participants,
  setParticipants,
  guest,
  physical,
  physicalDay1,
  physicalDay2,
  physicalDay3,
  child,
  childDay1,
  childDay2,
  childDay3,
  malaysianOnly,
  validity,
  setValidity,
  minPax,
}) => {
  const titleOptions = [
    "Prof",
    "Prof Dr",
    "Prof Dato' Dr",
    "Assoc Prof",
    "Assoc Prof Dr",
    "Asst Prof",
    "Asst Prof Dr",
    "Dr",
    "Datuk",
    "Emeritus Prof Datuk Dr",
    "Datuk Dr",
    "Dato'",
    "Dato' Dr",
    "Dato' Sri Dr",
    "Datin",
    "Mr",
    "Mdm",
    "Ms",
  ];
  const countrySelection = ["Malaysia"].concat(
    [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Angola",
      "Argentina",
      "Armenia",
      "Azerbaijan",
      "Bangladesh",
      "Belarus",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia And Herzegovina",
      "Botswana",
      "Brazil",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "China",
      "Colombia",
      "Comoros",
      "Congo, Dem. Rep.",
      "Congo, Rep.",
      "Costa Rica",
      "Cote D'ivoire",
      "Cuba",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt, Arab Rep.",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Eswatini",
      "Ethiopia",
      "Fiji",
      "Gabon",
      "Gambia, The",
      "Georgia",
      "Ghana",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Haiti",
      "Honduras",
      "India",
      "Indonesia",
      "Iran, Islamic Rep.",
      "Iraq",
      "Jamaica",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea, Dem. People's Rep.",
      "Kosovo",
      "Kyrgyz Republic",
      "Lao Pdr",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Maldives",
      "Mali",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia, Fed. Sts.",
      "Moldova",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nepal",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Macedonia",
      "Pakistan",
      "Palau",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Russian Federation",
      "Rwanda",
      "Samoa",
      "Sao Tome And Principe",
      "Senegal",
      "Serbia",
      "Sierra Leone",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Sri Lanka",
      "St. Lucia",
      "St. Vincent And The Grenadines",
      "Sudan",
      "Suriname",
      "Syrian Arab Republic",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Tunisia",
      "Turkiye",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "Uzbekistan",
      "Vanuatu",
      "Viet Nam",
      "West Bank And Gaza",
      "Yemen, Rep.",
      "Zambia",
      "Zimbabwe",
      "American Samoa",
      "Andorra",
      "Antigua And Barbuda",
      "Aruba",
      "Australia",
      "Austria",
      "Bahamas, The",
      "Bahrain",
      "Barbados",
      "Belgium",
      "Bermuda",
      "British Virgin Islands",
      "Brunei Darussalam",
      "Canada",
      "Cayman Islands",
      "Channel Islands",
      "Chile",
      "Croatia",
      "Curacao",
      "Cyprus",
      "Czechia",
      "Denmark",
      "Estonia",
      "Faroe Islands",
      "Finland",
      "France",
      "French Polynesia",
      "Germany",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Guam",
      "Guyana",
      "Hong Kong Sar, China",
      "Hungary",
      "Iceland",
      "Ireland",
      "Isle Of Man",
      "Israel",
      "Italy",
      "Japan",
      "Korea, Rep.",
      "Kuwait",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao Sar, China",
      "Malta",
      "Monaco",
      "Nauru",
      "Netherlands",
      "New Caledonia",
      "New Zealand",
      "Northern Mariana Islands",
      "Norway",
      "Panama",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Romania",
      "San Marino",
      "Saudi Arabia",
      "Seychelles",
      "Singapore",
      "Sint Maarten (Dutch Part)",
      "Slovak Republic",
      "Slovenia",
      "Spain",
      "St. Kitts And Nevis",
      "St. Martin (French Part)",
      "Sweden",
      "Switzerland",
      "Trinidad And Tobago",
      "Turks And Caicos Islands",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Virgin Islands (U.S.)",
    ].sort()
  );

  const mealOptions = ["Vegetarian", "Vegan", "Others", "None"];
  const comingAsOptions =
    minPax === 1 && owner === index
      ? [
          "Adult SBH (21 y/o and above)",
          "Father",
          "Mother",
          "Spouse",
          "Caregiver",
          "Others",
        ]
      : [
          "Child SBH (3 - 20 y/o)",
          "Adult SBH (21 y/o and above)",
          "Father",
          "Mother",
          "Spouse",
          "Caregiver",
          "Others",
        ];
  const equipments = ["Wheelchair", "Crutches", "Neither"];
  const [languages, setLanguages] = useState(
    guest?.languages?.length > 0 ? guest?.languages : []
  );
  const [selected, setSelected] = useState(
    guest?.product_ids ? guest?.product_ids : null
  );
  const [selectedPhysicalDay1, setSelectedPhysicalDay1] = useState(
    guest?.product_ids ? guest?.product_ids : null
  );
  const [selectedPhysicalDay2, setSelectedPhysicalDay2] = useState(
    guest?.product_ids ? guest?.product_ids : null
  );
  const [selectedPhysicalDay3, setSelectedPhysicalDay3] = useState(
    guest?.product_ids ? guest?.product_ids : null
  );

  const [emailDelay, setEmailDelay] = useState();
  const [isEmailExists, setIsEmailExists] = useState(false);

  useEffect(() => {
    let valid = false;
    if (guest) {
      if (guest.id !== owner) {
        valid =
          guest.title.length > 0 &&
          guest.full_name.length > 0 &&
          guest.email.length > 0 == !isEmailExists &&
          guest.phone.length > 0 &&
          guest.country.length > 0 &&
          (guest.country === "Malaysia") == guest.ic_number.length > 0 &&
          (guest.country !== "Malaysia") == guest.passport.length > 0 &&
          guest.meal_preference.length > 0 &&
          (guest.meal_preference === "Others") ==
            guest.meal_preference_other.length > 0 &&
          guest.coming_as.length > 0 &&
          (guest.coming_as === "Others") == guest.other_coming_as.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.equipment.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.gender.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.languages.length > 0 &&
          guest.product_ids.length > 0;
      } else {
        valid =
          guest.coming_as.length > 0 &&
          (guest.coming_as === "Others") == guest.other_coming_as.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.equipment.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.gender.length > 0 &&
          (guest.coming_as === "Child SBH (3 - 20 y/o)" ||
            guest.coming_as === "Adult SBH (21 y/o and above)") ==
            guest.languages.length > 0 &&
          guest.product_ids.length > 0;
      }
    }
    setValidity(
      validity.map((v) => {
        if (v.id === index) {
          return {
            ...v,
            valid: valid,
          };
        } else {
          return v;
        }
      })
    );
  }, [guest, isEmailExists]);

  useEffect(() => {
    if (
      selected ||
      selectedPhysicalDay1 ||
      selectedPhysicalDay2 ||
      selectedPhysicalDay3
    ) {
      updateParticipant(
        "product_ids",
        [
          selected,
          selectedPhysicalDay1,
          selectedPhysicalDay2,
          selectedPhysicalDay3,
        ].filter(Boolean)
      ); // Filter out any null or undefined values
    }
  }, [
    selected,
    selectedPhysicalDay1,
    selectedPhysicalDay2,
    selectedPhysicalDay3,
  ]);

  const handleSelect = (e) => {
    updateParticipant(e.target.name, e.target.value);
  };

  const checkEmail = async (emailValue) => {
    try {
      const response = await fetch(`/check_email?email=${emailValue}`);
      const data = await response.json();
      if (data.success) {
        setIsEmailExists(false);
      } else {
        setIsEmailExists(true);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  const handleEmailInput = (e) => {
    if (emailDelay) {
      clearTimeout(emailDelay);
    }
    setEmailDelay(
      setTimeout(() => checkEmail(e.target.value.toLowerCase()), 500)
    );
    updateParticipant(e.target.name, e.target.value.toLowerCase());
  };
  const handleInput = (e) => {
    updateParticipant(e.target.name, e.target.value);
  };
  const selectLanguage = (lang) => {
    let langArr = languages;
    if (languages.includes(lang)) {
      langArr = languages.filter((language) => language !== lang);
    } else {
      langArr = [...languages, lang];
    }
    setLanguages(langArr);
    updateParticipant("languages", langArr);
  };

  const updateParticipant = (key, value = "") => {
    let updated;
    let newList = [...participants];
    if (key === "coming_as") {
      setLanguages([]);
      setSelected([]);
    }

    updated = newList.map((guest) => {
      if (guest.id === index) {
        if (key === "coming_as") {
          return {
            ...guest,
            gender: "",
            languages: "",
            equipment: "",
            product_ids: [],
            [key]: value,
          };
        } else if (key === "country") {
          return {
            ...guest,
            ic_number: "",
            passport: "",
            [key]: value,
          };
        } else {
          return {
            ...guest,
            [key]: value,
          };
        }
      } else {
        return guest;
      }
    });
    setParticipants(updated);
  };

  const handleRadio = (e) => {
    const productsId = (
      guest?.coming_as === "Child SBH (3 - 20 y/o)" ? child : physical
    ).map(({ id }) => id);
    const selectedId = parseInt(e.target.value);
    if (selected.includes(selectedId)) {
      return;
    }
    setSelected((prev) => [...prev, selectedId]);
    console.log("selectedId", selectedId);
  };

  return (
    <div
      className={
        validity.find((v) => v.id === index)?.valid
          ? "activate communityCard"
          : "deactivate communityCard"
      }
    >
      {/* if choose as owner of account, no need fill in account details in step1 */}
      {owner && owner === index && (
        <div className="form-check mb-4">
          <input
            className="form-check-input"
            disabled={owner && owner !== index}
            name={`guest_${index}`}
            type="checkbox"
            checked={owner === index}
            id={`guest_${index}`}
            onChange={() => {
              // if (owner === index) {
              //   setOwner(null)
              // } else {
              //   setOwner(index)
              // }
            }}
          />
          <label className={owner && owner !== index ? `text-muted` : ""}>
            I am the participant and my details are the same as what I have
            submitted during the account creation process.
          </label>
        </div>
      )}
      {/* fill in new account details if not owner */}
      {owner && owner === index ? (
        <></>
      ) : (
        <div className="step1">
          <label>Title</label>
          <select
            className="form-control"
            name="title"
            value={guest?.title}
            onChange={handleSelect}
          >
            <option value="">- Select -</option>
            {titleOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <label>Full Name</label>
          <input
            className="form-control"
            name="full_name"
            value={guest?.full_name}
            onChange={handleInput}
          />
          <label>Country</label>
          <select
            className="form-control"
            name="country"
            value={guest?.country}
            onChange={handleSelect}
          >
            <option value="">- Select -</option>
            {countrySelection.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {/* if not malaysian */}
          {guest?.country === "Malaysia" && (
            <>
              <label>IC number</label>
              <input
                className="form-control"
                name="ic_number"
                value={guest?.ic_number}
                onChange={handleInput}
              />
            </>
          )}
          {guest?.country?.length > 0 && guest?.country !== "Malaysia" && (
            <>
              <label>Passport number</label>
              <input
                className="form-control"
                name="passport"
                value={guest?.passport}
                onChange={handleInput}
              />
            </>
          )}
          <label>Meal Preference</label>
          <select
            className="form-control"
            name="meal_preference"
            value={guest?.meal_preference}
            onChange={handleSelect}
          >
            <option value="">- Select -</option>
            {mealOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {guest?.meal_preference === "Others" && (
            <div>
              <label>Please specify dietary requirement</label>
              <input
                className="form-control"
                name="meal_preference_other"
                value={guest?.meal_preference_other}
                onChange={handleInput}
              />
            </div>
          )}
          <label>Email</label>
          <input
            className="form-control"
            name="email"
            type="email"
            value={guest?.email}
            onChange={handleEmailInput}
          />
          {isEmailExists && (
            <p>
              <small className="text-danger">
                * Email has already been taken.
              </small>
            </p>
          )}
          <label>Phone Number</label>
          <input
            className="form-control"
            name="phone"
            value={guest?.phone}
            onChange={handleInput}
          />
        </div>
      )}
      {/* if (minPax === 1 && owner === index) => owner is not child less than 21 => filter out child option */}
      <div className="step2">
        <div className="form-group">
          <label>I am Coming as</label>
          <select
            className="form-control"
            name="coming_as"
            value={guest?.coming_as}
            onChange={handleSelect}
          >
            <option value="">- Select -</option>
            {comingAsOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {guest?.coming_as === "Others" && (
            <div>
              <label>Please specify</label>
              <input
                className="form-control"
                name="other_coming_as"
                value={guest?.other_coming_as}
                onChange={handleInput}
              />
            </div>
          )}
        </div>
      </div>
      {/* Only If choose Child SBH / Adult SBH, let them fill in this : */}
      {(guest?.coming_as === "Child SBH (3 - 20 y/o)" ||
        guest?.coming_as === "Adult SBH (21 y/o and above)") && (
        <div className="step3">
          <div className="form-group">
            <label>Gender</label>
            <select
              className="form-control"
              name="gender"
              value={guest?.gender}
              onChange={handleSelect}
            >
              <option value="">- Select -</option>
              {["Male", "Female"].map((gen) => (
                <option key={gen} value={gen}>
                  {gen}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <p className="my-1">Language</p>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input my-0"
                checked={languages.includes("Bahasa Malaysia")}
                onChange={() => selectLanguage("Bahasa Malaysia")}
              />
              <label>Bahasa Malaysia</label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input my-0"
                checked={languages.includes("English")}
                onChange={() => selectLanguage("English")}
              />
              <label>English</label>
            </div>
          </div>
          <div className="form-group">
            <label>Do you use</label>
            <select
              className="form-control"
              name="equipment"
              value={guest?.equipment}
              onChange={handleSelect}
            >
              <option value="">- Select -</option>
              {equipments.map((equipment) => (
                <option key={equipment} value={equipment}>
                  {equipment}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {/* product pass available */}
      {guest?.coming_as && (
        <>
          <div className="activate card p-4">
            <div>
              <h6>ICSBH 2024 Conference Ticket</h6>
              {(guest?.coming_as === "Child SBH (3 - 20 y/o)"
                ? child
                : physical
              ).map(({ id, price, soldOut, productName, displayBuyerType }) => {
                return (
                  <div className="form-check" key={id}>
                    <input
                      disabled={soldOut}
                      className="form-check-input"
                      name={`guest-${index}-ticket`}
                      type="radio"
                      checked={selected == id}
                      id={`guest-${index}-${id}`}
                      value={id}
                      onChange={() => {
                        setSelected(id);
                        setSelectedPhysicalDay1(null);
                        setSelectedPhysicalDay2(null);
                        setSelectedPhysicalDay3(null);
                      }}
                    />
                    <label
                      className={`form-check-label ${
                        soldOut ? "text-decoration-line-through" : ""
                      }`}
                      htmlFor={`guest-${index}-${id}`}
                    >
                      <small>{productName}</small>
                      <br />
                      <span className="fw-light">{displayBuyerType}</span>
                      <br />
                      <span className="text-danger">{`- ${price}.00`}</span>
                      <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          {malaysianOnly && (
            <>
              <h5 className="text-center pt-5">Daily Pass Ticket</h5>
              <div className="activate card p-4">
                <div>
                  <h6>ICSBH 2024 Conference Day 1 Ticket</h6>
                  {(guest?.coming_as === "Child SBH (3 - 20 y/o)"
                    ? childDay1
                    : physicalDay1
                  ).map(
                    ({ id, price, soldOut, productName, displayBuyerType }) => {
                      return (
                        <div className="form-check" key={id}>
                          <input
                            disabled={soldOut}
                            className="form-check-input"
                            name={`guest-${index}-ticket-day-1`}
                            type="radio"
                            checked={selectedPhysicalDay1 == id}
                            id={`guest-${index}-${id}`}
                            value={id}
                            onChange={() => {
                              setSelectedPhysicalDay1(id);
                              setSelected(null);
                              if (
                                selectedPhysicalDay2 &&
                                selectedPhysicalDay3
                              ) {
                                setSelectedPhysicalDay2(null);
                                setSelectedPhysicalDay3(null);
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${
                              soldOut ? "text-decoration-line-through" : ""
                            }`}
                            htmlFor={`guest-${index}-${id}`}
                          >
                            <small>{productName}</small>
                            <br />
                            <span className="fw-light">{displayBuyerType}</span>
                            <br />
                            <span className="text-danger">{`- ${price}.00`}</span>
                            <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="activate card p-4">
                <div>
                  <h6>ICSBH 2024 Conference Day 2 Ticket</h6>
                  {(guest?.coming_as === "Child SBH (3 - 20 y/o)"
                    ? childDay2
                    : physicalDay2
                  ).map(
                    ({ id, price, soldOut, productName, displayBuyerType }) => {
                      return (
                        <div className="form-check" key={id}>
                          <input
                            disabled={soldOut}
                            className="form-check-input"
                            name={`guest-${index}-ticket-day-2`}
                            type="radio"
                            checked={selectedPhysicalDay2 == id}
                            id={`guest-${index}-${id}`}
                            value={id}
                            onChange={() => {
                              setSelectedPhysicalDay2(id);
                              setSelected(null);
                              if (
                                selectedPhysicalDay1 &&
                                selectedPhysicalDay3
                              ) {
                                setSelectedPhysicalDay1(null);
                                setSelectedPhysicalDay3(null);
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${
                              soldOut ? "text-decoration-line-through" : ""
                            }`}
                            htmlFor={`guest-${index}-${id}`}
                          >
                            <small>{productName}</small>
                            <br />
                            <span className="fw-light">{displayBuyerType}</span>
                            <br />
                            <span className="text-danger">{`- ${price}.00`}</span>
                            <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="activate card p-4">
                <div>
                  <h6>ICSBH 2024 Conference Day 3 Ticket</h6>
                  {(guest?.coming_as === "Child SBH (3 - 20 y/o)"
                    ? childDay3
                    : physicalDay3
                  ).map(
                    ({ id, price, soldOut, productName, displayBuyerType }) => {
                      return (
                        <div className="form-check" key={id}>
                          <input
                            disabled={soldOut}
                            className="form-check-input"
                            name={`guest-${index}-ticket-day-3`}
                            type="radio"
                            checked={selectedPhysicalDay3 == id}
                            id={`guest-${index}-${id}`}
                            value={id}
                            onChange={() => {
                              setSelectedPhysicalDay3(id);
                              setSelected(null);
                              if (
                                selectedPhysicalDay1 &&
                                selectedPhysicalDay2
                              ) {
                                setSelectedPhysicalDay1(null);
                                setSelectedPhysicalDay2(null);
                              }
                            }}
                          />
                          <label
                            className={`form-check-label ${
                              soldOut ? "text-decoration-line-through" : ""
                            }`}
                            htmlFor={`guest-${index}-${id}`}
                          >
                            <small>{productName}</small>
                            <br />
                            <span className="fw-light">{displayBuyerType}</span>
                            <br />
                            <span className="text-danger">{`- ${price}.00`}</span>
                            <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ maxWidth: "600px" }}>
                  <small className="fst-italic">
                    <b>Note:</b> By purchasing daily tickets, you will receive a
                    certificate of attendance. Please note that the Malaysian
                    Medical Association (MMA) does not award CPD points for
                    daily ticket holders.
                  </small>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
